<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="kt-header kt-grid__item"
    v-bind:class="headerClasses"
  >
    <div class="news" v-if="0">
      <!-- <sliderNotification :textArr="textArr"></sliderNotification> -->
    </div>

    <!-- begin:: Header Menu -->
    <div id="kt_header_menu_wrapper" class="kt-header-menu-wrapper"></div>
    <!-- end:: Header Menu -->
    <KTTopbar></KTTopbar>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTMenu from '@/assets/js/menu.js';
import KTOffcanvas from '@/assets/js/offcanvas.js';
import KTTopbar from '@/views/theme/topbar/Topbar.vue';
// import sliderNotification from '@/components/SliderNotification/Universal';

export default {
  name: 'KTHeader',
  components: {
    KTTopbar
    // sliderNotification
  },
  data() {
    return {
      textArr: [
        // { text: '2条消息：', icon: 'bell-fill', class: 'red' },
        {
          html:
            '1、您有新的待发货订单需要处理，请及时发货。<br>2、您有新的用户首单物流存在问题，请关注。',
          // icon: 'bell-fill',
          class: 'red'
        },
        {
          html: '2、您有新的用户首单物流存在问题，请关注。',
          // icon: 'bell-fill',
          class: 'red'
        }
      ]
    };
  },
  mounted() {
    new KTOffcanvas(this.$refs['kt_header_menu_wrapper'], {
      overlay: true,
      baseClass: 'kt-header-menu-wrapper',
      closeBy: 'kt_header_menu_mobile_close_btn',
      toggleBy: {
        target: 'kt_header_mobile_toggler',
        state: 'kt-header-mobile__toolbar-toggler--active'
      }
    });

    new KTMenu(this.$refs['kt_header_menu'], {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion'
      },
      accordion: {
        // accordion toggle slide speed in milliseconds
        slideSpeed: 200,
        // allow having multiple expanded accordions in the menu
        expandAll: false
      },
      dropdown: {
        timeout: 50
      }
    });
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    }
  }
};
</script>
<style scoped>
.news {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
}
</style>
